import { useState, useEffect } from "react";
import React from "react";
import { Link } from "react-router-dom";
import { MdHome, MdOutlineMenu } from "react-icons/md";
import { GrProjects } from "react-icons/gr";
import { IoIosDocument } from "react-icons/io";
import { IoChevronBackOutline } from "react-icons/io5";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { SiHandshake } from "react-icons/si";
import { useAnimate, stagger, motion } from "framer-motion";

const staggerMenuItems = stagger(0.1, { startDelay: 0.15 });
const buttons = [
  <>
    <MdOutlineMenu size={30} className="m-4" />
  </>,
  <>
    <IoChevronBackOutline size={30} className="m-4" />
  </>,
];
function Navbar(props) {
  const [menuScope, menuAnimate] = useAnimate();
  const [buttonCount, setButtonCount] = useState(0);

  const toggleMenu = () => {
    props.setIsOpen((open) => !open);
    setButtonCount((n) => {
      if (n === 0) {
        return 1;
      }
      return 0;
    });
  };
  useEffect(() => {
    menuAnimate(
      ".nav-component",
      props.isOpen ? { x: 0 } : { x: "var(--links-from)" },
      {
        delay: props.isOpen ? staggerMenuItems : 0,
      }
    );
  });
  return (
    <>
      <motion.div
        key={buttonCount}
        className={`menu-icon absolute cursor-pointer lg:hidden`}
        onClick={toggleMenu}
        initial={{ x: -200 }}
        animate={{ x: 0 }}
        transition={{ delay: 0.5, duration: 1 }}
      >
        {buttons[buttonCount]}
      </motion.div>
      <div
        className={`navbar flex flex-col items-center justify-center h-screen align-center ${
          props.isOpen ? "" : "max-lg:hidden"
        }  [--links-from:-700px] lg:[--links-from:0px] lg:[--links-y:-700px] lg:justify-start lg:items-start lg:m-6 lg:absolute lg:w-[calc(100vw-1.5rem)] lg:h-min`}
      >
        <div className="navbar-links *:*:*:flex items-center justify-center flex flex-col *:*:m-4 lg:flex-row lg:w-[calc(100vw-1.5rem)]">
          <div
            className="site-links flex-grow *:*:justify-start flex flex-col lg:flex-row"
            ref={menuScope}
          >
            <motion.div
              className="nav-component"
              initial={{ y: "var(--links-y, 0px)" }}
              animate={{ y: 0 }}
              transition={{ duration: 0.7, ease: "backInOut" }}
            >
              <Link to="/">
                <MdHome size={40} />
                <span className="m-2 mb-6 text-lg lg:text-xl xl:font-medium lg:mb-0">
                  Home
                </span>
              </Link>
            </motion.div>
            <motion.div
              className="nav-component"
              initial={{ y: "var(--links-y, 0px)" }}
              animate={{ y: 0 }}
              transition={{ delay: 0.1, duration: 0.7, ease: "backInOut" }}
            >
              <Link to="/projects">
                <GrProjects className="m-1" size={30} />
                <span className="m-2 mb-6 text-lg lg:text-xl xl:font-medium lg:mb-0">
                  Projects
                </span>
              </Link>
            </motion.div>
            <motion.div
              className="nav-component"
              initial={{ y: "var(--links-y, 0px)" }}
              animate={{ y: 0 }}
              transition={{ delay: 0.2, duration: 0.7, ease: "backInOut" }}
            >
              <Link to="/experience">
                <IoIosDocument size={40} />
                <span className="m-2 mb-6 text-lg lg:text-xl xl:font-medium lg:mb-0">
                  Experience
                </span>
              </Link>
            </motion.div>
          </div>

          <motion.div
            initial={{ y: "var(--right-links-from)" }}
            animate={{ y: 0 }}
            transition={{ delay: 1, duration: 1 }}
            key={buttonCount}
            className="[--right-links-from:400px] lg:[--right-links-from:-400px] nav-component absolute max-lg:bottom-4 flex align-bottom *:mx-6 lg:relative lg:align-center lg:mr-4 text-3xl 2xl:text-4xl 2xl:*:mx-8"
          >
            <a href="https://github.com/zhangj23">
              <FaGithub />
            </a>
            <a href="https://www.linkedin.com/in/justin-zhang-94a406256/">
              <FaLinkedin />
            </a>
            <a href="https://rpi.joinhandshake.com/stu/users/51963642">
              <SiHandshake />
            </a>
          </motion.div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
