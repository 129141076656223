export const experiences = [
  {
    name: "Philippine American League",
    date: "April 2024 - Present",
    image: "pal.png",
    role: "Full Stack/Webmaster",
    description: (
      <>
        <li>
          Created a responsive, animated website for 1,500+ students using{" "}
          <b>React</b> and <b>MongoDB</b>
        </li>
        <li>
          Designed an admin system to securely manage event data through{" "}
          <b>OAuth</b> and stored images with Imgur API
        </li>
        <li>
          Increased social media engagement by <b>20%</b> through creating video
          content and informative graphics
        </li>
      </>
    ),
  },
  {
    name: "Staten Island Tech",
    date: "September 2022 - June 2023",
    image: "hs.png",
    role: "Lead Backend Developer",
    description: (
      <>
        <li>
          Spearheaded backend development for a full-stack website using{" "}
          <b>Django</b> with 1,300+ active student users
        </li>
        <li>
          Designed efficient database integration models, improving data
          retrieval speed by <b>30%</b>
        </li>
        <li>
          Enhanced server performance, reducing operational costs by <b>20%</b>{" "}
          using data loaders
        </li>
      </>
    ),
  },
  {
    name: "Biz Tech Ninjas",
    date: "Februrary 2023 - May 2023",
    image: "biz.png",
    role: "Web Developement Intern",
    description: (
      <>
        <li>
          Developed a responsive website using <b>HTML</b> and <b>CSS</b> to
          enhance online presence and accessibility
        </li>
        <li>
          Increased business consultations by <b>25%</b> by deploying{" "}
          <b>user-centered UX</b> design to improve customer engagement
        </li>
      </>
    ),
  },
  {
    name: "Wooter Apparel",
    date: "July 2022 - September 2022",
    image: "wooter.png",
    role: "Data Analyst Intern",
    description: (
      <>
        <li>
          Utilized <b>BeautifulSoup4 </b>to scrape product data and gained
          valuable experience in data extraction and organization
        </li>
        <li>
          Increased company revenue by utilizing collected data to devise
          marketing strategies for 10,000+ customers
        </li>
      </>
    ),
  },
];
