import React from "react";
import { Link } from "react-router-dom";

function Error() {
  return (
    <>
      <div className="font-ibm flex items-center h-screen">
        <div className="w-3/4 text-center flex flex-col m-auto">
          <h1 className="text-8xl lg:text-9xl">404</h1>
          <p className="mt-14 text-lg">
            The page that you are looking for has not been found
          </p>
          <p className="text-lg mt-10">
            Click{" "}
            <Link className="underline" to="/">
              here
            </Link>{" "}
            to go to the home page
          </p>
        </div>
      </div>
    </>
  );
}

export default Error;
