import React from "react";
import { useParams } from "react-router-dom";
import { projects } from "../assets/data.jsx";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { IoChevronBackOutline } from "react-icons/io5";
import Error from "./Error.tsx";

function Project() {
  const { name } = useParams();
  const result = projects.filter((project) => project.path === "/" + name);
  if (result.length > 0) {
    const data = result[0];
    return (
      <>
        <div className="lg:[--title-x:-500px] flex font-ibm bg-gradient-to-r from-[#28333e] via-[#333333] via-33% to-[#494848] to-100% h-min-screen">
          <Link to="/projects">
            <IoChevronBackOutline className="text-3xl m-4 lg:m-8 lg:text-4xl xl:text-5xl absolute cursor-pointer" />
          </Link>
          <div className="ml-12 mt-20 lg:ml-28 xl:mt-28 w-full">
            <motion.h1
              initial={{ x: "var(--title-x, 0px)", y: 500, scale: 1 / 4 }}
              animate={{ x: 0, y: 0, opacity: 1, scale: 1 }}
              transition={{ transition: 1 }}
              className="w-5/6 lg:w-2/3 text-4xl sm:text-4xl text-white lg:text-5xl xl:text-6xl font-semibold title"
            >
              {data.description}
            </motion.h1>
            <motion.img
              initial={{ x: -200, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.5 }}
              className="w-5/6 lg:w-2/3 h-1/3 object-cover rounded-md mt-8 image"
              src={`/` + data.img}
              alt={data.id + ` image`}
            />
            <div className="*:*:mb-8 *:*:font-ibm *:*:text-base *:*:sm:text-lg *:*:lg:text-xl *:*:xl:text-2xl *:*:font-thin *:text-white  *:mt-8 *:w-11/12 *:lg:w-2/3">
              <motion.div
                initial={{ opacity: 0, y: 200 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.7 }}
              >
                {data.info}
              </motion.div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return <Error />;
  }
}

export default Project;
