import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import ProjectLink from "../components/ProjectLink.tsx";
import { projects } from "../assets/data.jsx";
import { motion } from "framer-motion";

function Projects() {
  const location = useLocation();
  console.log(localStorage.getItem("route"));
  const previousLocationPathname = localStorage.getItem("route") || "";
  const shouldAnimate = !previousLocationPathname.includes("projects");
  useEffect(() => {
    localStorage.setItem("route", location.pathname);
  });

  return (
    <>
      <div className="page-container bg-gradient-to-r from-[#28333e] via-[#333333] via-33% to-[#494848] to-100% text-[#FBEBD9] h-screen font-ibm lg:h-full overflow-x-hidden [--title-from-x:0px] lg:[--title-from-x:-400px] [--title-from-y:calc(50vh-5rem)] lg:[--title-from-y:-100px]">
        <motion.div
          initial={{ x: "var(--title-from-x)" }}
          animate={{ x: 0 }}
          transition={{ duration: 0.7, delay: 0.2 }}
        >
          <Link to="/">
            <IoChevronBackOutline className="text-3xl m-4 lg:m-8 md: md:text-4xl xl:text-5xl absolute cursor-pointer" />
          </Link>
        </motion.div>

        <div className="page-contents mt-14 flex justify-center flex-col m-auto lg:mt-20 xl:mt-28 w-[calc(70vw)] md:w-5/6">
          {shouldAnimate ? (
            <motion.h1
              initial={{
                x: "var(--title-from-x)",
                y: "var(--title-from-y)",
                scale: 1 / 4,
              }}
              animate={{ x: 0, y: 0, scale: 1 }}
              transition={{ duration: 0.5 }}
              className="text-6xl text-center font-semibold h-max sm:text-7xl md:text-8xl md:mb-4
              
              "
            >
              Projects
            </motion.h1>
          ) : (
            <h1 className="text-6xl text-center font-semibold h-max sm:text-7xl md:text-8xl mb-4">
              Projects
            </h1>
          )}

          <div className="projects md:grid-cols-2 lg:grid-cols-3 grid gap-8 my-12 overflow-hidden">
            {projects.map((project, i) => (
              <motion.div
                initial={{ y: 400, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  delay: 0.15 * i + (shouldAnimate ? 0.3 : 0),
                }}
                className="m-auto"
              >
                <ProjectLink
                  image={project.img}
                  id={project.id}
                  name={project.name}
                  languages={project.languages}
                  date={project.date}
                  path={project.path}
                  className="project-link"
                />
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Projects;
