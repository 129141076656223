const year = new Date().getFullYear();

export const skills = [
  {
    name: "React",
    image: "react.jpg",
    time: year - 2023,
  },
  {
    name: "Vue",
    image: "vue.jpg",
    time: year - 2021,
  },
  {
    name: "CSS",
    image: "css.jpg",
    time: year - 2020,
  },
  {
    name: "C++",
    image: "cpp.jpg",
    time: year - 2020,
  },
  {
    name: "Figma",
    image: "figma.jpg",
    time: year - 2021,
  },
  {
    name: "Firebase",
    image: "firebase.jpg",
    time: year - 2021,
  },
  {
    name: "Python",
    image: "python.jpg",
    time: year - 2020,
  },
  {
    name: "Git",
    image: "git.jpg",
    time: year - 2020,
  },
  {
    name: "HTML",
    image: "html.jpg",
    time: year - 2020,
  },
  {
    name: "Javascript",
    image: "javascript.jpg",
    time: year - 2020,
  },
  {
    name: "Node.js",
    image: "node.jpg",
    time: year - 2021,
  },
  {
    name: "PHP",
    image: "php.jpg",
    time: year - 2023,
  },
  {
    name: "SQL",
    image: "sql.jpg",
    time: year - 2021,
  },
  {
    name: "TypeScript",
    image: "typescript.jpg",
    time: year - 2022,
  },
  {
    name: "Kubernetes",
    image: "kubernetes.png",
    time: year - 2024,
  },
  {
    name: "MongoDB",
    image: "mongo.webp",
    time: year - 2023,
  },
  {
    name: "Exrpess",
    image: "express.png",
    time: year - 2023,
  },
];
