import React from "react";
import { useState, useEffect } from "react";
import Navbar from "../components/Navbar.tsx";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { skills } from "../assets/skills.jsx";
import Skill from "../components/Skill.tsx";
import { FaAnglesDown } from "react-icons/fa6";

function Home() {
  const location = useLocation();
  localStorage.setItem("route", location.pathname);
  const [isOpen, setIsOpen] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", function () {
      setIsScroll(true);
    });
  });
  return (
    <>
      <div className="page-container bg-gradient-to-r from-[#28333e] via-[#333333] via-33% to-[#494848] to-100% text-[#FBEBD9] h-screen font-ibm lg:h-full overflow-x-hidden">
        <Navbar isOpen={isOpen} setIsOpen={setIsOpen}></Navbar>
        <motion.div
          key={+isOpen}
          initial={{ x: -500 }}
          animate={{ x: 0 }}
          className={`${
            isOpen ? "max-lg:hidden" : ""
          }  justify-center flex h-screen items-center align-center lg:h-[calc(100vh)]`}
        >
          <div className="text-3xl w-2/3 font-bold lg:w-1/2 lg:text-5xl">
            <h1>Hello,</h1>
            <h1>I'm Justin Zhang</h1>
            <motion.p
              className="text-sm mt-4 font-jetbrains font-normal lg:text-lg"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1, duration: 2 }}
            >
              I'm a sophomore at Renesslaer Polytechnic Institute pursuing a
              major in Computer Science and ITWS. Take a look around!
            </motion.p>
          </div>
          <motion.div
            className="absolute bottom-8 m-auto text-4xl"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.5 }}
          >
            <motion.div
              animate={{ y: isScroll ? -2000 : 8, opacity: isScroll ? 0 : 1 }}
              transition={{
                repeat: isScroll ? 0 : Infinity,
                duration: 1,
                repeatType: "mirror",
                delay: 0,
              }}
            >
              <FaAnglesDown />
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
      <div className="bg-white h-full overflow-y-hidden">
        <h2 className="text-6xl text-black text-center font-poppins pt-8 pb-4 font-bold">
          Skills
        </h2>``
        <div className="flex m-auto w-2/3 lg:w-5/6">
          <div className="grid lg:grid-cols-2 place-items-center mb-24 w-full">
            {skills.map((skill, i) => (
              <Skill image={skill.image} name={skill.name} time={skill.time} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
