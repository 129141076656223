import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

function Skill(props) {
  const [barWidth, setbarWidth] = useState(40);
  useEffect(() => {
    const width = window.innerWidth;
    if (width >= 1800) {
      setbarWidth(80);
    } else if (width >= 1280) {
      setbarWidth(70);
    } else if (width >= 1024) {
      setbarWidth(50);
    } else if (width >= 768) {
      setbarWidth(70);
    } else if (width >= 640) {
      setbarWidth(60);
    }
  }, []);

  return (
    <>
      <div className="flex items-center my-2 lg:[--width:40px]">
        <motion.div
          initial={{ y: 150, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true }}
        >
          <motion.div className="rounded-full shadow-md w-16 h-16 mr-6 p-2">
            <img className="w-12 h-12" src={props.image} alt={props.name} />
          </motion.div>
          <motion.p className="text-black w-16 text-center font-ibm">
            {props.name}
          </motion.p>
        </motion.div>

        <motion.div
          initial={{ width: 0 }}
          whileInView={{ width: 5 * barWidth }}
          transition={{ delay: 0.5 }}
          className="h-3 bg-slate-200 rounded-sm"
          viewport={{ once: true }}
        >
          <motion.div
            initial={{ width: 0 }}
            whileInView={{
              width: barWidth * props.time * 0.7 + barWidth * 1.5,
            }}
            transition={{ delay: 1 }}
            className="h-3 bg-black rounded-sm"
            viewport={{ once: true }}
          ></motion.div>
          <motion.p
            className="flex text-black font-ibm justify-end"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 1.5 }}
            viewport={{ once: true }}
          >
            {props.time === 0 ? "< 1" : props.time > 4 ? "4+" : props.time} year
            {props.time === 1 || props.time === 0 ? "" : "s"}
          </motion.p>
        </motion.div>
      </div>
    </>
  );
}

export default Skill;
