import React, { useEffect } from "react";
import { IoChevronBackOutline } from "react-icons/io5";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { experiences } from "../assets/experiences.jsx";
import Experience from "../components/Experience.tsx";

function Experiences() {
  const location = useLocation();
  console.log(localStorage.getItem("route"));
  const previousLocationPathname = localStorage.getItem("route") || "";
  const shouldAnimate = !previousLocationPathname.includes("experience");
  console.log(shouldAnimate);
  useEffect(() => {
    localStorage.setItem("route", location.pathname);
  });
  return (
    <>
      <div className="min-h-screen font-ibm [--title-from-x:0px] lg:[--title-from-x:-400px] [--title-from-y:calc(50vh-5rem)] lg:[--title-from-y:-100px]">
        <Link to="/">
          <IoChevronBackOutline className="text-3xl m-4 lg:m-8 lg:text-4xl xl:text-5xl cursor-pointer" />
        </Link>
        {shouldAnimate ? (
          <motion.h1
            initial={{
              x: "var(--title-from-x)",
              y: "var(--title-from-y)",
              scale: 1 / 4,
            }}
            animate={{ x: 0, y: 0, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="m-8 text-5xl font-semibold h-max sm:text-7xl md:text-8xl md:mb-4 md:m-12"
          >
            Experience
          </motion.h1>
        ) : (
          <h1 className="m-8 text-5xl font-semibold h-max sm:text-7xl md:text-8xl mb-4 md:mx-12">
            Experience
          </h1>
        )}
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="h-fit"
        >
          <div className="h-[150vh] mx-14 mt-4 z-1 border-l-[1px] border-white absolute md:h-[170vh] md:mx-16 md:mt-6 lg:mx-20 lg:mt-8 xl:mx-[6.5rem] 2xl:mx-[7.5rem]"></div>
          {experiences.map((experience) => {
            return (
              <Experience
                description={experience.description}
                name={experience.name}
                image={experience.image}
                date={experience.date}
                role={experience.role}
              />
            );
          })}
        </motion.div>
      </div>
    </>
  );
}

export default Experiences;
