import React from "react";

function Experience(props) {
  return (
    <>
      <div className="flex z-2 relative my-8 lg:my-12">
        <div className="left">
          <img
            src={props.image}
            alt="Logo"
            className="w-24 m-2 md:w-28 lg:m-4 lg:w-32 xl:w-36 xl:mx-8 2xl:mx-10 2xl:w-40 rounded-full"
          />
        </div>
        <div className="w-2/3">
          <p className="text-gray-300 md:text-lg lg:text-xl xl:text-2xl">
            {props.date}
          </p>
          <h2 className="font-bold text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
            {props.name}
          </h2>
          <p className="text-xl md:text-2xl lg:text-3xl lg:my-1 text-blue-300">
            {props.role}
          </p>
          <ul className="list-disc md:text-lg lg:text-xl xl:text-2xl *:m-1">
            {props.description}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Experience;
