import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAnimate } from "framer-motion";

function ProjectLink(props) {
  const [isHover, setIsHover] = useState(false);
  const [infoScope, infoAnimate] = useAnimate();
  useEffect(() => {
    infoAnimate(".info", isHover ? { y: 0 } : { y: 300 });
  });
  return (
    <>
      <div
        onMouseEnter={() => setIsHover((hover) => true)}
        onMouseLeave={() => setIsHover((hover) => false)}
        className="m-auto relative shadow-lg overflow-hidden"
        ref={infoScope}
      >
        <Link to={`/projects${props.path}`} className="router overflow-hidden">
          <img
            src={props.image}
            alt={props.id}
            className={`object-cover rounded-md h-[calc(70vw)] lg:h-[calc(26vw)] lg:w-[calc(30vw)] md:h-[calc(40vw)] md:w-[calc(40vw)] ${
              isHover ? "blur-sm brightness-50" : ""
            }`}
          />
          <div
            className={`info absolute bottom-0 text-[#FFFFFF] ${
              isHover ? "" : "hidden"
            } overflow-hidden w-full`}
          >
            <h2 className="font-bold text-3xl mb-4 mx-2">{props.name}</h2>
            <div className="text-lg flex justify-between font-jetbrains">
              <p className="left-5 mb-2 ml-2">{props.languages}</p>
              <p className="right-5 mb-2 mr-2">{props.date}</p>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
}

export default ProjectLink;
